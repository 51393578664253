import labour from "../Assets/img/service/labourDel.png";
import newBornImg from "../Assets/img/service/newBorn.png";
import * as url from "../Utils/ServiceUrl";
import brestFeedingImg from "../Assets/img/service/bresfeedingBasics-updated.jpg";
import cprImg from "../Assets/img/service/cpr-update.jpg";
import dayTimeOverImg from "../Assets/img/service/overtime.png";
import intialLactationImg from "../Assets/img/service/remote-lactation-service.jpg";
import phoneCallDesc from "../Assets/img/service/phoneCallDesc.png";
import phoneCallMob from "../Assets/img/service/phoneCallMob.png";
import prebaby_featured from "../Assets/img/service/prebaby-inner-featured.jpg";
import s_14_mobile from "../Assets/img/service/s_14_mobile.png";
import s_14_desktop from "../Assets/img/service/s_14_mobile.png";
import s_8_mobile from "../Assets/img/service/s_8_mobile.png";
import s_8_desktop from "../Assets/img/service/s_8_mobile.png";
import s_7_mobile from "../Assets/img/service/s_7_mobile.png";
import s_7_desktop from "../Assets/img/service/s_7_desktop.png";
import s_6_mobile from "../Assets/img/service/s_6_mobile.png";
import s_6_desktop from "../Assets/img/service/s_6_desktop.png";
import s_5_mobile from "../Assets/img/service/s_5_mobile.png";
import s_5_desktop from "../Assets/img/service/s_5_desktop.png";
import s_4_mobile from "../Assets/img/service/s_4_mobile.png";
import s_4_desktop from "../Assets/img/service/s_4_desktop.png";
import s_24_desktop from "../Assets/img/service/s_24_desktop.jpg";
import s_24_mobile from "../Assets/img/service/s_24_mobile.jpg";
import s_23_desktop from "../Assets/img/service/s_23_desktop.jpg";
import s_23_mobile from "../Assets/img/service/s_23_mobile.jpg";
import s_25_desktop from "../Assets/img/service/s_25_desktop.jpg";
import s_25_mobile from "../Assets/img/service/s_25_mobile.jpg";

import dayOfDischarge from "../Assets/img/service/dayOfDischarge.jpg";

import {
  LACTATIONCONSULT,
  PRIVATECLASS,
  SLEEPCONSULT,
  PRIVATECLASS2,
} from "../Routes/RouteConstent";
import { usePostHog } from "posthog-js/react";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import posthog from "posthog-js";

let serviceData: any = [
  {
    tittle: "Pre Baby Bootcamp",
    posthogEvent: "pre-baby-bootcamp",
    includedInMembership: true,
    price: "399",
    taught:
      " Pediatric Nurse Practitioners, Labor and Delivery and Postpartum Registered Nurses, Lactation Consultants; with expert guest speakers in Pelvic Floor Physical Therapy and Licensed Mental Health Counselors",
    cDuration: "9 hours over the course of two half day weekend sessions",
    classType:
      "Live, Virtual - materials sent to your home to fully participate from home",
    // description: "Learn how to take care of your baby and yourself.There is SO MUCH to learn throughout the pregnancy and early parenting journey. This bootcamp is your one-stop course, teaching you all the basics you need to know. You’ll get all your prenatal classes done in one weekend, plus some essential baby items and support. The class is 9 hours total, spread over two days.",
    topics: [
      "Labor Prep including how to know you are in labor, inductions, medicated and non  medicated pain management options",
      "Pushing",
      "Postpartum Recovery",
      "Mental health for all parents",
      "Bathing, diapering, and how to care for your baby",
      "Breastfeeding, bottles, and pumping",
      "Newborn sleep cycles",
      "Car seat and sleep safety",
    ],
    otherData: "",
    testimonial: [
      {
        name: "Taylor N.",
        data: "Having NAPS as resources throughout my first pregnancy has been a godsend. The Nurture By Naps program is a game changer in terms of all things pregnancy, L&D, and baby prep. I’m amazed at how much content is available - it covers every possible topic and question. My husband and I both participated in the Baby Bootcamp which did wonders in terms of helping build our confidence. I will definitely use their services once Baby arrives. The Nurture By Naps program is well worth the investment.",
      },
      {
        name: "Max K.",
        data: "NAPS was the best investment we made into our baby! We took virtual classes and used NAPS's service before and after our baby girl was born - it helped us both so much. I was able to get ready for the baby and learn essential first-aid and CPR skills to prepare. My wife did the same, but also continued to use their service after our baby arrived for new mom support and lactation consultation. We are in Austin, TX so we did everything virtually, and it was just as good as being in-person.",
      },
    ],
    duration: `<div className='topic-cover'>
        <p className='strong'>Learn how to take care of your baby and yourself.<p>

        <p>There is SO MUCH to learn throughout the pregnancy and early parenting journey. This bootcamp is your one-stop course, teaching you all the basics you need to know. You’ll get all your prenatal classes done in one weekend, plus some essential baby items and support. The class is 9 hours total, spread over two days.
        </p>
        <p> Jamie and Emily, co-founder of NAPS,  designed this course after being fed up with the overmedicalized and  outdated prenatal classes offered at most hospitals. This course combined Emily and Jamie’s combined 15+ years of experience as labor and delivery nurses with their real life experience of being moms themselves and helping support 10,000+ parents in labor and after delivery in their homes. </p>
        <p> It’s designed  to be fun, interactive, and informative. Yes, we said FUN!  Don’t believe us? Check out the testimonials below! You’ll also meet other parents who are going through the same thing, so you can start to build your parenting community.</p>
        <p class="strong"> You can also add on Infant and Child CPR at checkout for a discount to complete your prenatal class curriculum. Bootcamp AND Infant and Child CPR are included in our annual membership program. So if you want education and support that lasts for the first year of your life as a parent, check out the annual program for savings and ongoing support.</p>
        
        </div>
`,
    image: prebaby_featured,
    service: "service_13",
    url: url.PREBABYBOOT_URL,
    privateClass: { text: "Book One PBB" },
    giftCardOptions: [{ service: "13", price: "399", btn: "Book One PBB" }],
  },
  {
    tittle: "Labor and Delivery Prep",
    posthogEvent: "labor-and-delivery-prep",
    includedInMembership: true,
    price: "125",
    subtitle: "Taught by a labor and delivery Registered Nurse",
    cDuration: "3 hours",
    classType: "Live, Virtual",
    description: "",
    topics: [
      "Soothing pregnancy discomfort",
      "Understanding the routine tests done at your check-ups with your medical team",
      "Learning about birth options (vaginal vs c-section)",
      "Creating a birth plan",
      "Deciding on preferred pain management",
      "Planning for recovery from labor and birth",
      "Pinpointing ways your partner can support you through this process",
    ],
    otherData: "",
    testimonial: [
      {
        name: "Catrina S.",
        data: "We had a Birth class last night, and it went very well and I loved the convenience of having it on a weekday in the evening after work. It was very relaxed and also very informative. As first time parents  we definitely feel more at ease after having a nurse answer all our questions and run through possible scenarios. I would definitely recommend NAPS to my friends and I will use more of their services after the baby arrives.",
      },
    ],
    duration: ` <div className='topic-cover'>
        
        <p className='strong'>What does labor really look like?</p>
        <p>Attend our virtual Labor & Delivery Prep class and learn everything you need to know about your upcoming delivery. We’ll cover some of your burning questions, talk through some fears, and tell you what to watch out for as you head into your last trimester of pregnancy. </p>
        <p>From pain management to recovery, this class will give you all the info you need to prepare for getting that baby out! Some of the things we’ll cover include pregnancy discomforts and remedies,when to call your doctor/midwife, how the f!ck to know you’re in labor,  vaginal vs. c-section delivery, medicated vs. non medicated pain management, and ways your partner can support you. Although it’s virtual, these classes are taught live, so you’ll have the opportunity to interact with the instructor, ask questions, and vibe with the class as a whole. 
        <p className='strong'>This live, group class is included with an annual Nurture by NAPS membership, along with all of your other prenatal classes (Newborn Essentials, Breastfeeding Basics, and Infant and Child), a sleep consultation, and more!</p>
        </p>
        </div>
`,
    image: labour,
    service: "service_12",
    url: url.LABOUR_DELEVERY_URL,
    giftCardOptions: [{ service: "12", price: "125", btn: "Book Class" }],
  },
  {
    tittle: "Lactation Consultation",
    posthogEvent: "lactation-consultation",
    includedInMembership: true,
    points: [
      {
        title: "What to Expect:",
        description:
          "Every feeding consult session is tailored to your needs, which means that no matter how you’re feeding your baby, an LC can be helpful. Here are some of the things your LC can provide:",
      },
    ],
    price: "325*",
    subtitle: "Proved by a lactation certified registered nurse",
    cDuration: "Appointments are 1-2 hours based on the type of visit.",
    classType: "In-person (for MA residents only) or Virtual",
    topics: [
      "Latch and positioning tutorials",
      "Baby weight checks, both pre- and post-feeding (for in person)",
      "Customized plans to help with feeding goals",
      "Breast pump flange measurements and tutorials on pump use",
      "Instruction on when and how to introduce a bottle",
      "Guidance with baby’s struggling to take a bottle",
      "Prenatal consultations to address and plan for anticipated  or previous feeding concerns ",
      "Newborn care tips related to sleep, soothing, bathing, and more",
    ],
    groupButtons: [
      {
        text: "Book an In-home Consult",
        url: PRIVATECLASS2,
        price: "349*",
        sendGift: {},
        publicRoute: true,
      },
    ],
    otherData: "",
    testimonial: [
      {
        name: "NA",
        data: "BEST thing I ever did during my first two months with my newborn was hire one of their lactation consultants. She came over to my house 3 separate times, and each session was SO valuable. First, she helped me with everything breastfeeding and made me feel so much more at ease starting that journey. I then had her come back to help me with severe latching pain, and she was able to tweak my positioning and pain went completely away! third time, she was like my therapist and really helped me make the decision to stop breastfeeding. I talked to so many people about it, but she was the one that really made me feel like I was doing the right thing and I was still a great mother for deciding to switch to formula.",
      },
      {
        name: "Nikki B",
        data: "I had to share how amazing this company is! I had a meeting with one of their lactation consultants and it was so incredibly helpful. Breast feeding is like a full time job, and there is so little education on it while pregnant, so to meet with a nurse and get one on one feedback was so necessary. Meg was kind and professional and helped me feel more confident in what I am doing. I am already planning on another session to discuss pumping and returning to work. If you’re on the fence with whether or not to make an appointment, DO IT! I would absolutely recommend NAPS to all my pregnant and mama friends - they are an amazing resource!",
      },
    ],
    duration: ` <div className='topic-cover'>
                    <p> <span className='strong'>We recommend that every new parent — breastfeeding and/or bottle feeding — book an appointment with a Lactation Consultant*.</span> Our lactation consultants are also Registered Nurses, and can address any feeding and/or medical concerns no matter how small or big. We offer two choices, an in-home lactation consultation (for MA residents) or a virtual lactation consultation. </p>
                    <p><span className='strong'>We accept many insurance options</span>, including: Anthem PPO, BCBS PPO, UnitedHealthcare PPO and HMO, VA Optum Easat, Premera, Regence, Oxford, or a plan that includes the Multiplan or PNOA on your insurance card. <span className='strong'>Click <a href="https://registration.mytln.care/?practiceId=001RP00000KP1OnYAL">here</a> to check your eligibility</span> for the insurance covered care we offer through The Lactation Network. Prices are reflective of cost per visit if not covered by insurance.</p>
                </div>
              `,
    image: intialLactationImg,
    service: "service_3",
    url: url.REMOTE_LACTATION_URL,
    privateClass: { text: "Book a Virtual Consult" },
    giftCardOptions: [
      { service: "3", price: "349", btn: "Book a Virtual Consult" },
    ],
    accordion: [
      {
        title: "WHO",
        description: "Our team is unlike any other in that we are a team of Lactation Consultants (CLCs / IBCLCs) who are also Registered Nurses…that is not the norm to be both a RN and CLC or IBCLC. This means we can address more issues than just feeding concerns, and also have the medical background and training to address any underlying medical concerns for both you and your baby.",
      },
      {
        title: "COST",
        description: "<span className='strong'>We accept many insurance options</span>, including: Anthem PPO, BCBS PPO, UnitedHealthcare PPO and HMO, VA Optum Easat, Premera, Regence, Oxford, or a plan that includes the Multiplan or PNOA on your insurance card. <span className='strong'>Click <a href='https://registration.mytln.care/?practiceId=001RP00000KP1OnYAL'>here</a> to check your eligibility</span> for the insurance covered care we offer through The Lactation Network. If you are covered through insurance, there is no cost to you except for our one time service fee* ($40). You are also responsible to provide parking to the lactation consultant. If there is no option for free parking, you will be charged for any parking costs the lactation consultant incurs. The costs listed above are reflective of the cost per visit if not covered by insurance, there are no service fees for visits not covered by insurance.",
      },
      {
        title: "WAIT TIME",
        description: "Upon receiving your request either after checking coverage eligibility (usually a wait time of 1-3 day) or directly through our website, We will respond to your request within 24 hours Monday-Friday, and within 48 hours on the weekends. We then typically book your appointment within 2-3 days.",
      },
      {
        title: "WHAT",
        description: "We start with addressing your immediate needs and concerns for however you are choosing to feed your baby. We listen to you first, hear about your troubles and goals, observe a feeding session, and then provide advice, feedback, and a plan based on what we see, what you have told us, and your overall goals for feeding. If time allows, we can also discuss additional topics like pumping, sleep, baby wearing, safe milk storage, soothing your baby, etc or these things plus assessing your plan or addressing additional feeding concerns can happen during a follow up appointment.",
      },
      {
        title: "WHERE",
        description: "In your home (for MA residents only) or virtually for all!",
      },
    ],
  },

  {
    tittle: "Nursing Care Info Call",
    posthogEvent: "nursing-care-info-call",
    free: true,
    subtitle: "Provided by NAPS Team Member",
    points: [
      { title: "Appointment Duration:", description: "30 minutes" },
      { title: "Appointment Type:", description: "Phone" },
    ],
    topicsTitle: "What to Expect:",
    topics: [
      "Time for us  to learn more about you, your family, your questions, and needs",
      "A rundown of our nursing care team including their background, experience and training",
      "How to request and book shifts",
      "Pricing for one off shifts vs. packages",
      "A breakdown of how our nurses can help you both during the day and/or overnight",
      "Next steps for moving forward with care",
    ],
    privateClass: {
      text: "Book a Call",
      outsideUrl:
        "https://app.acuityscheduling.com/schedule.php?owner=14289123&appointmentType=8484669",
    },
    otherData: "",
    testimonial: [
      {
        name: "Christina F.",
        data: "Prior to having my second, I knew that NAPS was an amazing company - providing women with much needed education, support, and community. But I really didn't understand what it would be like in practice. I think what I couldn't have understood was how it all clicks together. How their admin, nurse practitioner, night nurse, and lactation consultant  all work together to create a really unique support system for mom and baby. To have the chance to hop on a call with a nurse practitioner and ask about a goopy eye while also getting great insight on developmental stages of a 3-week old is a godsend - much better than Dr. deep-web-google. And that the nurse practitioner is in touch with the night nurse and knows how many ounces my baby takes at night... And then there's my night nurse - who I look forward to seeing at 10pm in a sick way. She is so sweet, helpful, and reassuring. Getting a good night's sleep makes me such a better person, mom, and wife - it makes me feel so much less frantic and much more logical. And then to have the lactation consultant come by and help with breastfeeding - really taking the time to get to know us and provide such personal advice on how to best feed my baby (in a really nice/non-judgmental way) - it's just so insanely helpful",
      },
    ],
    duration: ` <div className='topic-cover'>
        
        <p><span className='strong'>We value personalized care,</span> which is why we always begin with a 30-minute phone call with clients interested in daytime or overnight nursing care support. It allows us to better understand your specific needs and recommend the best format, nursing team member and services for you and your baby.</p>
        <p>Please schedule your <span className='strong'>free</span> informational call with us,  and a NAPS team member will call you at the scheduled time. If you do not see a date and time that works for you, please email us <a href="mailto: hello@nurturebynaps.com">here</a>. </p>
        </div>
`,
    image: dayTimeOverImg,
    service: "service_1",
    url: url.CALL30MIN_URL,
    giftCardOptions: [],
  },
  {
    tittle: "New Mom Survival Group (MSG)",
    posthogEvent: "new-moms-survival-group",
    includedInMembership: true,
    price: "249",
    subtitle: "Run by a Registered Nurse and mom ",
    cDuration: "75 minute session x 6 session",
    classType: "Live, Virtual",
    description: "",
    topics: [
      "Weekly content covering topics like",
      [
        "Health and Wellness for Mom",
        "Health and wellness for baby",
        "Infant sleep",
        "Identity shift and the return to work or the plan to stay home",
        "Relationships and communication",
      ],
      "Non-judgmental support and community",
      "Friendships that will last a lifetime ",
    ],
    otherData: "",
    testimonial: [
      {
        name: "Courtney",
        data: "I cannot express fully how important Jamie and Emily, and the entire NAPS team, meant to me and my family as we navigated becoming parents…in a global pandemic. Most importantly, probably is my MSG who are still very close over two years later where we celebrates our joint 2 year birthday party together in person. THANK YOU!",
      },
    ],
    duration: ` <div className='topic-cover'>

        <p className='strong'>Just starting your parenting journey? Meet other moms walking this new motherhood journey alongside you.</p>
        <p>Join a six-week virtual support group for moms with babies the same age as yours. Each week, your group will learn about a new topic from a Registered Nurse, share experiences, and grow together. </p>
        <p className='strong'>An MSG is included for annual Nurture Members. Want to have support for these 6 weeks and beyond? Join Nurture!</p>
`,
    image: s_7_desktop,
    mobileImage: s_7_mobile,
    service: "service_7",
    url: url.NEW_MOM_S_GROUP_URL,
    privateClass: { text: "Book an MSG" },
    giftCardOptions: [{ service: "7", price: "249", btn: "Book an MSG" }],
  },
  {
    tittle: "Veteran Mom Survival Group (MSG)",
    posthogEvent: "veteran-mom-survival-group",
    includedInMembership: true,
    price: "249",
    subtitle: "Run by a Registered Nurse and veteran mom ",
    cDuration: "75 minute session x 6 sessions",
    classType: "Live, Virtual",
    description: "",
    topics: [
      "Weekly content covering topics like",
      [
        "Health and Wellness for Mom & Baby",
        "Health and wellness for baby",
        "Strategies for challenging toddler behaviors",
        "Infant & toddler sleep",
        "Identity shift and the return to work or the plan to stay home",
        "Relationships and communication",
      ],
      "Non-judgmental support and community",
      "Friendships that will last a lifetime ",
    ],
    otherData: "",
    testimonial: [
      {
        name: "Jaime",
        data: "I just wanted to thank you so much for your wonderful advice during the breastfeeding support group. I have joined it a couple of times since the birth of my daughter and you have been such a calming and reassuring presence. I truly appreciate how you acknowledge all elements of the breastfeeding journey: the emotional, the physical, the mental. You start your answer to almost every question with the fact that the mom is doing a great job! It is something mommas do not hear often enough at times and I’m so glad you always say that and create the space for it.",
      },
      {
        name: "Kate L.",
        data: "I've been going to Southie with Ali and it's PHENOMENAL. I look forward to it every time and can't understand why they aren't packed!!  ",
      },
    ],
    duration: ` <div className='topic-cover'>
        
        <p className='strong'>Been down this road before? Meet some other moms who are parenting a newborn and an older kid or two.</p>
        <p>Take part in a six-week virtual support group for moms who have been through it all before. You’ll get tailored advice for balancing life with older siblings as you welcome your new addition.</p>
        <p>An MSG is included for annual Nurture Members. Want to have support for these 6 weeks and beyond? Join Nurture!</p>
        </div>
`,
    image: s_5_desktop,
    imageMobile: s_5_mobile,
    service: "service_5",
    url: url.V_MOM_S_GROUP_URL,
    giftCardOptions: [{ service: "5", price: "249", btn: "Book Class" }],
  },
  {
    tittle: "Working Mom Survival Group (MSG)",
    posthogEvent: "working-mom-survival-group",
    includedInMembership: true,
    price: "249",
    subtitle: "Run by a Registered Nurse and working mom",
    cDuration: "75 minute session x 6 sessions",
    classType: "Live, Virtual",
    description: "",
    topics: [
      "Weekly content covering topics like",
      [
        "Basics of emotional intelligence",
        "Confronting self criticism",
        "Boundary Setting",
        "Self Compassion",
        "Managing anxiety and shame",
        "Navigating hard conversations",
      ],
      "Non-judgmental support and community",
      "Friendships that will last a lifetime ",
    ],
    otherData: "",
    testimonial: [
      {
        name: "Allie",
        data: "Working MSG was like a revolution for my mindset after having baby #2. 18 months of working remotely without a like-minded community of ambitious women was like living underground. Our first session made me feel empowered and ready for any challenge. Using the tools provided, I was able to take a hard look at my life, the biases I was facing each day.. and flip the script. It was just the kick in the ass I needed to leave my job for a one with more flexibility, higher salary & longer maternity leave.",
      },
    ],
    duration: ` <div className='topic-cover'>
       
        <p className='strong'>It will come as a surprise to no one to hear that balancing parenting and work is seriously tough. Join a group of people who really understand.</p>
        <p>This virtual support group, led by experts on our NAPS team, is designed for working moms of infants and toddlers. This group focuses on the unique challenges working moms face, whether they’re going back to working in-person or from home. </p>
        <p>An MSG is included for annual Nurture Members. Want to have support for these 6 weeks and beyond? Join Nurture!</p>
        </div>
`,
    image: s_6_desktop,
    mobileImage: s_6_mobile,
    service: "service_6",
    url: url.W_MOM_S_GROUP_URL,
    giftCardOptions: [{ service: "6", price: "249", btn: "Book Class" }],
  },
  {
    tittle: "Free Breastfeeding Support Group",
    posthogEvent: "free-breastfeeding-support-group",
    taught: "a Registered Nurse and IBCLC",
    cDuration: "60 minutes",
    classType: "Live, Virtual or In-Person",
    description: "",
    topics: [
      "Non-judgemental support and community",
      "Answers to your questions about any feeding methods",
      "Support from a lactation certified registered nurse",
      "Validation that other moms have similar questions and concerns",
      "Welcoming of all feeding types",
      "A scale for a pre and post feed weight (in person groups only)",
    ],
    otherData: "",
    testimonial: [
      {
        name: "Jaime",
        data: "I just wanted to thank you so much for your wonderful advice during the breastfeeding support group. I have joined it a couple of times since the birth of my daughter and you have been such a calming and reassuring presence. I truly appreciate how you acknowledge all elements of the breastfeeding journey: the emotional, the physical, the mental. You start your answer to almost every question with the fact that the mom is doing a great job! It is something mommas do not hear often enough at times and I’m so glad you always say that and create the space for it.",
      },
    ],
    duration: ` <div className='topic-cover'>
        <p className='strong'>Feeding your baby (in anyway!) can be a truly special journey… but that doesn’t mean it’s not tough. Meet some other moms who are going through similar challenges.</p>
        <p>Join this free drop-in support group, held both in person and virtually every week. Anyone can drop in to ask questions and bond with other moms. Breastfeeding, pumping, and bottle-feeding parents and  questions are welcome.</p>
        </div>
`,
    image: s_4_desktop,
    mobileImage: s_4_mobile,
    service: "service_4",
    url: url.BREASTFEEDING_SUPPORT_URL,
    privateClass: {
      text: "Join an in person group",
      // url: PRIVATECLASS,
      outsideUrl:
        "https://app.acuityscheduling.com/schedule.php?owner=14289123&appointmentType=category:In%20Person%20Support%20Groups%20",
    },
    groupButtons: [
      { text: "Join a virtual group", sendGift: {}, service: "service_4" },
    ],
    giftCardOptions: [],
  },
  {
    tittle: "Infant and Child CPR with First Aid",
    posthogEvent: "infant-and-child-cpr-with-first-aid",
    price: "225",
    taught: "a Basic Life Support (BLS) certified Registered Nurse",
    cDuration: "2 hours",
    classType: "live, virtual or in-home private",
    description: "",
    topics: [
      "How to know when to perform CPR",
      "The technique to perform CPR properly for infants and children",
      "How to know if your infant or child is choking",
      "The skills to assist an infant or child who is choking",
      "How to handle common first aid occurrences like allergic reactions, head injuries, cuts, burns, and more",
    ],
    otherData: "",
    testimonial: [
      {
        name: " Jessica B",
        data: "We did the infant/child CPR and first aid course with Meg, and it was absolutely fantastic. Meg made it both fun and informative, and accessible to all levels - even the resident doctor in the family learned a lot! We all feel much more confident and empowered to respond in emergency situations, and as a bonus it was just good family QT. The booking process was also extremely easy and it was so convenient having the course at home. Highly recommend for any new or even seasoned parents or families.",
      },
      {
        name: " Jessica G",
        data: "I wanted to reach out and let you know how amazing Nicole was. We had such a great time today learning from her. I cannot say enough fabulous things about her. She is so personable and was such a great teacher. I absolutely loved this experience and would so welcome the opportunity to work with her again in the future. We had a lot of laughs too! Thank you for offering such a wonderful option to new parents. I feel so much better having the tools I learned today",
      },
    ],
    privateClass: { text: "Book Private Class", url: PRIVATECLASS },
    groupButtons: [
      {
        text: "Virtual Class",
        service: "service_10",
        price: "125",
        sendGift: {},
        includedInMembership: true,
      },
    ],
    duration: ` <div className='topic-cover'>
        
        <p className="strong">Did you know that the #1 reason why people don’t perform CPR is because they’re afraid they’ll do it wrong?</p>
        <p>There aren’t many things scarier than worrying about your child not breathing or becoming unresponsive. But the key to these situations is knowing what to do, and being able to act quickly. Which is why learning the skills to know how to handle  these emergencies is essential. </p>
        <p>The class is based on the guidelines provided by the American Heart Association’s Family and Friends CPR Anytime Program, and is a course designed for parents, caregivers, and members of the community to know what to do until help arrives.* This course can be taken as a live, virtual class or if you live in MA, you can request a private in-home class. </p>
        <p className="strong">The live, group class is included for our annual Members of Nurture by NAPS. </p>
        <p>* this course is not a BLS certification course</p>
       
        </div>
`,
    image: cprImg,
    service: "service_10",
    url: url.INFANT_CHILD_CPR_URL,
    giftCardOptions: [{ service: "10", price: "125", btn: "Virtual Class" }],
  },
  {
    tittle: "Newborn Essentials",
    posthogEvent: "newborn-essentials",
    includedInMembership: true,
    price: "125",
    taught: " a postpartum Registered Nurse",
    cDuration: "2 hours",
    classType: "Live, Virtual",
    description: "",
    topics: [
      "Understanding the difference between a baby and an adult",
      "Normal newborn behaviors",
      "What to expect right after your delivery",
      "Newborn poops and pees",
      "Newborn sleep cycle",
      "Newborn feeding patterns and ways to feed (breastfeeding vs. bottle feeding)",
      "Introducing bottles and pumping",
      "Feeding your baby, diapering your baby and bathing your baby",
      "Soothing your baby with the 5 S's",
      "Newborn safety, screening and vaccinations",
      "Car seat safety",
    ],
    testimonial: [
      {
        name: " Erin G.",
        data: "I loved the newborn essentials  course that NAPS offered. The nurse was thorough and informative. I did not leave the class feeling overwhelmed. It was a relaxing space that encouraged asking questions and made it feel really ok to be unsure. I would highly recommend!",
      },
    ],
    otherData: "",
    duration: ` <div className='topic-cover'>
        <p className='strong'>So much love… and puke… and poop… in such a small package.</p>
        <p>Even if you’ve taken care of other people’s babies, trust us, there are things you won’t expect. This class will walk you through everything you need to know for your first few weeks at home and beyond, from the ins and outs of poops and pee to the 5 s’s of soothing. You’ll learn how to bathe, swaddle, and feed your baby, plus some strategies for extending those brief hours of sleep at night!</p>
        
        <p className='strong'>
        This live, group class is included with an annual Nurture by NAPS membership, along with all of your other prenatal classes (Labor Prep, Breastfeeding Basics, and Infant and Child), a sleep consultation, and more!</p>
        </div>
`,
    image: newBornImg,
    service: "service_11",
    url: url.NEW_BORN_URL,
    giftCardOptions: [{ service: "11", price: "125", btn: "Book Class" }],
  },
  {
    tittle: "Breastfeeding Basics",
    posthogEvent: "breastfeeding-basics",
    includedInMembership: true,
    price: "125",
    taught: " a Registered Nurse and Lactation Consultant",
    cDuration: "2 hours",
    classType: "Live, Virtual",
    description: "",
    topics: [
      "Anatomy and physiology of breastfeeding",
      "How our bodies make milk",
      "The first hour of life after delivery",
      "Skin-to-skin contact with your baby after delivery and beyond",
      "Initiating and sustaining a successful latch",
      "Positioning your baby during breastfeeding",
      "How to know your baby is getting enough",
      "How and when to start pumping based on your unique situation",
    ],
    otherData: "",
    testimonial: [
      {
        name: " Courtney S.",
        data: "NAPS is a godsend!! We first took the breastfeeding class which was very informative. Two days after being home from the hospital,  a lactation nurse was over guiding me through breastfeeding and figuring out what the heck to do! I honestly don’t know what I would’ve done in those first few weeks without NAPS help and guidance.",
      },
    ],
    duration: ` <div className='topic-cover'>
        
        <p className='strong'>No matter how you feed your baby, you’re going to be doing a LOT of it in the early days. </p>
        <p>This class will walk you through all the different ways to feed your baby including  latching your baby to the breast, pumping, bottles, and  formula. We also talk about combo feeding (breastfeeding and formula feeding), reasons to  supplement, safe storage of breastmilk, skin-to-skin contact, and how to know if your baby is getting enough food! </p>
        <p className='strong'>
        This live, group class is included with an annual Nurture by NAPS membership, along with all of your other prenatal classes (Labor Prep, Newborn Essentials, and Infant and Child), a sleep consultation, and more!</p>
       
        </div>
`,
    image: brestFeedingImg,
    service: "service_9",
    url: url.BREASTFEEDING_BASICS_URL,
    giftCardOptions: [{ service: "9", price: "125", btn: "Book Class" }],
  },
  {
    tittle: "Private Sleep Consultation",
    posthogEvent: "private-sleep-consultation",
    subtitle: "Consultation provided by a Pediatric Nurse Practitioner",
    price: "499",
    cDuration: "1.5 hours total (split between two calls)",
    classType: "1:1 Phone Consultation",
    description: "",
    topics: [
      "Intake form ahead of the initial call so we can dive right in!",
      "One, 1-hour initial consultation that will include the creation of a plan",
      "Copy of your  custom sleep plan",
      "One, 30-minute follow up call to evaluate the plan and answer questions",
    ],
    otherData: "",
    testimonial: [
      {
        name: "Lindsey B.",
        data: "OMG I loved this consult and swear by Ellen! It's not often you basically get a user's manual for a baby, especially when it comes to precious sleep!",
      },
    ],
    duration: ` <div className='topic-cover'>
       
        <p> <span className='strong'>Need some one-on-one sleep support? We can help.</span></p>
        <p>At NAPS, we know that not all children respond to the same methods. That’s why we offer personalized sleep consultations for you and your family.</p>
        <p>In our private sleep consult sessions, you’ll meet with our Pediatric Nurse Practitioner, who has more than 13 years of experience in childhood sleep issues. During your consultation she will address your big (and small) sleep concerns related to daytime and/or overnight sleep, create  a clear plan with you and your partner, and check in with you as you implement the plan during your follow up call. While group sleep consultations are included as an annual Nurture member, members  get 20% off a private sleep consultation.</p>
       
                `,
    image: s_14_desktop,
    mobileImage: s_14_mobile,
    service: "service_14",
    url: url.PRIVATE_SLEEP_CONSULT_URL,
    giftCardOptions: [{ service: "14", price: "499", btn: "Book Class" }],
  },
  {
    tittle: "Consult a Nurse",
    posthogEvent: "consult-a-nurse",
    includedInMembership: true,
    price: "125",
    subtitle:
      "Consultation provided by a Registered Nurse or Nurse Practitioner ",
    cDuration: "30 Minutes",
    classType: "1:1 Phone Consultation",
    description: "",
    topics: [
      "What to expect at your first OB appointment",
      "What to expect  with an induction",
      "Troubleshooting fussy/colicky babies",
      "Exclusive Pumping",
      "Transition from breastfeeding to formula",
      "Weaning",
      "Starting Solids",
      "Transiting back to work",
      "1 year old transitions",
      "Picky Eaters",
      "Challenging Toddler Behaviors",
    ],
    otherData: "",
    testimonial: [
      {
        name: " Jordan Z.",
        data: "I used their nurse calls to help with allergy advice and to help create a plan for siblings sharing a room. They are able to give really specific feedback even on 30 min calls!",
      },
    ],
    duration: ` <div className='topic-cover'>
        
        <p className='strong'>Need some one-on-one parental support? We've got your back! </p>
        <p>Reserve some private, 1:1 time with one of our Registered Nurses or nurse practitioners. You can talk about the challenges that are unique to you and your family during these 30-minute consultations. You can ask about anything from pregnancy to newborn care to difficult toddler behaviors.
        </p>
        <p>You’ll connect with one of our experienced and knowledgeable Registered Nurses over the phone, and get expert support and advice that is specific to your family. A virtual nurse consult is also included for our annual Nurture members.</p>
        <p>If you are looking for help specifically with sleep or breastfeeding, we recommend utilizing our <span class="link" data=${SLEEPCONSULT}>sleep consultation </span> and <span class="link" data=${LACTATIONCONSULT}>lactation consultations </span> services respectively.  </p>
        
        </div>
`,
    image: phoneCallDesc,
    mobileImage: phoneCallMob,
    service: "service_2",
    url: url.VIRTUAL_NURSE_COSNULT_URL,
    giftCardOptions: [{ service: "2", price: "125", btn: "Book Class" }],
  },
  {
    tittle: "Group Sleep Consults",
    posthogEvent: "group-sleep-consults",
    includedInMembership: true,
    price: "99",
    taught: "a registered nurse and sleep consultant",
    cDuration: "2 hours total (split between two, 1-hour calls)",
    classType: "Live, Virtual",
    description: "",
    topics: [
      "Small sleep support group that meets for two live, 1-hour sessions with a sleep consultant trained Registered Nurse",
      "Session 1 - a live, small group call where every participant will have time to share and ask questions, and leave with an action plan to help your babies get more sleep.",
      "Session 2 - a live check in with your group where each member will give an update, have time to address any new problems or challenges that have developed, and celebrate all of your big and small wins!",
    ],
    otherData: "",
    testimonial: [
      {
        name: "NA",
        data: "Can testify specific issues or not— The group sleep consult was AWESOME. Just great to have a cadre of others who are in the thick of it and the group leader is a sleep training hype woman!!!",
      },
    ],
    duration: ` <div className='topic-cover'>
        <p className='strong'>Not getting enough sleep because of your child? You’re not alone, and we can prove it!</p>
        <p>We’re proud to offer monthly sleep support groups. Through these groups, you get the best of both worlds: access to the knowledge of our pediatric Registered Nurse, and the encouragement and support of a community of other parents who are going through the same thing!</p>
        <p>The sessions are designed for parents of children who are between 5 and 18 months old. We ask that you please only sign up for a group consultation if you are able to attend both scheduled sessions. <span className='strong'> A group sleep consult is included for annual members to Nurture. </span></p>
       
        </div>
`,
    image: s_8_desktop,
    mobileImage: s_8_mobile,
    service: "service_8",
    url: url.GROUP_SLEEP_CONSULT_URL,
    giftCardOptions: [{ service: "8", price: "99", btn: "Book Class" }],
  },
  {
    tittle: "Welcome Home",
    posthogEvent: "welcome-home",
    price: "499",
    subtitle: "Provided by a Registered Nurse",
    cDuration: "4 hours",
    classType: "In person (MA residents only)",
    topics: [
      "A health history and wellness check for mom, including screening for postpartum depression and anxiety",
      "A weight check and assessment of your newborn",
      "Establishment of a feeding schedule that is inclusive of breastfeeding, pumping, bottle feeding and formula feeding",
      "Setting up a schedule with your newborn and learning how to maximize sleep",
      "Hands on education for your newborn including their first bath at home, umbilical cord care, circumcision care, filing nails and more",
      "Soothing techniques",
      "Reviewing all aspects of safety and care with your partner and other family members",
    ],
    otherData: "",
    testimonial: [
      {
        name: "Cassandra",
        data: "We had an excellent experience with Lauren, and I’m so glad we did it. She was thorough, friendly, supportive, knowledgeable, and not at all judgmental even though I’m sure some of our “new parents who have no experience with babies” questions seemed silly. She put our minds at ease that much of what we were doing to care for our then 2-week-old daughter was perfectly fine, and she shared a number of good tips to help us get more comfortable with basics like feeding positions, burping techniques, swaddling, etc. Lauren also did a nice job of talking through and assessing some very specific challenges I was having with pumping and recommending next steps and other NAPS resources I could take advantage of. Also, it was nice to have someone check in on my health/wellness.",
      },
      {
        name: "Carly",
        data: "This service absolutely saved my mental health from spiraling - a true life saver! Lauren entered our home and was immediately met with a new mom crying - she instantly addressed the issue, comforted me and let me know that this was normal and OK to be feeling this way. I am so thankful I scheduled this service and will absolutely recommend to anyone I know having their first baby or adding another child to their family. I had a list of questions to ask about me as well as my 8 day old baby, and Lauren answered them fully and in a way that made me feel empowered about the plan moving forward. My wife and I learned so much in the 4 hours Lauren spent with us, which made us feel so much more confident as new parents. Wish I could give 10 stars!",
      },
    ],
    duration: `
            <div className='topic-cover'>
                <p>
                    Whether you welcomed your first baby, you’re an experienced parent, or you’re now just being discharged home from a NICU stay - <span class="strong">all parents deserve better care than what is offered to you</span> through our current healthcare system.  This 4 hour appointment focuses on your new baby and family’s overall health and wellness.
                <p>
                <p>Our maternal/child health experienced Registered Nurse will check in on the baby, on the birthing mother, and the family as a whole. She will listen to your questions and concerns, and thoughtfully break down the steps and answers in a way that is manageable and digestible, leaving you feeling <span class="strong">more confident</span> and <span class="strong">less overwhelmed</span>.</p>
                <p>The ideal time to book this appointment is within your first few days up through the first 8 weeks.</p>
            </div>
        `,
    image: dayOfDischarge,
    service: "service_17",
    url: url.DAY_OF_DISCHARGE_URL,
    privateClass: { text: "Book Visit" },
    giftCardOptions: [{ service: "17", price: "499", btn: "Book Visit" }],
  },
  {
    tittle: "Dads Survival Group",
    posthogEvent: "dads-survival-group",
    price: "249",
    subtitle: "Run by a NAPS dad",
    cDuration: "60 minutes sessions x 6 sessions",
    classType: "Live, Virtual",
    topicsDescription: "",
    topics: [
      "Topics to discuss will be fluid each session, but can cover:",
      [
        "ways to ease the transition into fatherhood",
        "how to be a supportive spouse throughout pregnancy and parenting",
        "effective communication",
        "parenting with confidence",
      ],
    ],
    otherData: "",
    testimonial: [
      {
        name: "",
        data: "As a dad, NAPS has been incredibly helpful and supportive for me. They helped me find ways to support my wife and newborn child when I otherwise felt useless at times. The opportunity to talk to other dads going through the same experience gave me comfort that I wasn't alone with my struggles and hearing how they managed different situations was immeasurably helpful. The guidance I got directly from Jamie, Emily, and Ellen was equally invaluable. As we are about to welcome our second child, I'm so happy to know that NAPS is just a phone call away.",
      },
      {
        name: "",
        data: "I just can’t say enough good things about Naps. From their online content, to their many ways to ask professional Nurses questions directly, their social media posts and their podcasts, there are so many ways to get advice on childbirth and raising your child it they are so accessible to answer the many questions that any parent has on best approaches to handle whatever you need to help your child or yourselves. Their team of nurses and professionals, many whom are parents themselves are giving REAL advice without the fluff (my favorite point here NO FLUFF)! Naps is simply the greatest advice we were given as we entered parenthood and it’s the greatest gift I have been able to give to others as they enter their journey too.",
      },
    ],
    duration: ` 
      <div className='topic-cover'>
        <p className='strong'>Calling all dads! Parenthood is a team effort, and we're here to support you every step of the way. Join our Dads’ Survival Group, a monthly virtual support group designed just for you. Like its counterpart, the Moms’ Survival Guide, our sessions are set up as a 6 session series. Each month, we'll tackle a different discussion topic, led by a seasoned NAPS dad.</p>
        <p>Whether you're a new dad seeking advice or a seasoned pro with questions, our facilitated discussions provide a supportive space to share experiences, seek guidance, and connect with fellow fathers. Come join the conversation and let's navigate fatherhood together!</p>
        <p className='strong'>An DSG is included for annual Nurture Members. Want to have support for you and your partner for a whole year? Join Nurture!</p>
      </div>
    `,
    image: s_24_desktop,
    mobileImage: s_24_mobile,
    service: "service_24",
    url: url.DAD_S_GROUP_URL,
    privateClass: { text: "Book Now" },
    giftCardOptions: [{ service: "23", price: "249", btn: "Book Now" }],
  },
  {
    tittle: "Pregnant Moms Survival Group",
    posthogEvent: "pregnant-moms-survival-group",
    price: "249",
    subtitle: "Run by a NAPS registered nurse",
    cDuration: "60 minutes sessions x 6 sessions",
    classType: "Live, Virtual",
    description: "",
    topics: [
      "Pregnancy symptoms",
      "Labor and birth",
      "Newborn care and feeding choices",
      "Setting yourself up for success postpartum",
      "Mental health",
      "Advocacy, communication, and boundaries",
    ],
    otherData: "",
    testimonial: [
      {
        name: "",
        data: "I absolutely loved my Pregnant MSG! Being pregnant during the pandemic felt quite lonely as I was working from home, too, and meeting with the wonderful ladies from my group did not only make me feel connected, but also helped me immensely enjoy my pregnancy. We loved our group so much we turned it into a New Moms’ MSG right after all the babies were born. Thank you for all your support!",
      },
    ],
    duration: ` 
      <div className='topic-cover'>
        <p className='strong'>Calling all expecting moms! Pregnancy can be exciting and overwhelming, but you're not alone. Join our 6-session series to connect with other moms-to-be in a safe space. Led by experts, we'll cover everything from pregnancy concerns to mental health to preparing for postpartum. Plus, meet moms due the same month as you for added support and camaraderie. Let's navigate this journey together!</p>
        <p>Unlimited MSGs including pregnant, new, veteran, and working are included for annual Nurture Members. Want to have support for you and your partner for a whole year? Join Nurture!</p>
      </div>
    `,
    image: s_23_desktop,
    mobileImage: s_23_mobile,
    service: "service_23",
    url: url.PREGNANT_MOM_S_GROUP_URL,
    privateClass: { text: "Book Now" },
    giftCardOptions: [{ service: "23", price: "249", btn: "Book Now" }],
  },
  {
    tittle: "Veteran Bootcamp",
    posthogEvent: "veteran-bootcamp",
    price: "199",
    subtitle: "Run by a NAPS registered nurse and veteran mom",
    cDuration: "one-time, 3 hour crash course",
    topics: [
      "Labor and delivery refresher",
      "Newborn feeding and sleep routines",
      "Sibling preparation",
      "Tag teaming and communication with your partner",
    ],
    otherData: "",
    testimonial: [
      {
        name: "",
        data: "NAPS has been a welcomed resource during both of my pregnancies. Most recently, we attended the Veteran Parents’ Bootcamp. It was a great refresher on Labor and Delivery/Newborn Care, as well as helping come up with a plan for sibling prep. The class gave us the confidence boost we needed before welcoming baby #2! ",
      },
      {
        name: "",
        data: "The team at NAPS has been unbelievably helpful for our family! They really helped us in countless ways with our first and the veteran parents bootcamp has given us a lot of confidence as we prepare to welcome our second child later this year. Alex and Nicole answered so many questions, reminded us of what to expect in Labor and delivery, and provided some great tips on helping prepare our first child for becoming a sibling.",
      },
    ],
    duration: `
      <div className='topic-cover'>
        <p className='strong'>Attention expecting veteran parents! Are you gearing up for round two (or more)? Join our NAPS RN and veteran mom for a comprehensive crash course to refresh your knowledge on labor and delivery, newborn care essentials, sibling preparation, and strategies for seamless tag teaming and communication.<p>
        <p>Whether you're growing your family to four or more, our expert-led session offers practical guidance and support to navigate this exciting journey. Don't miss out on this essential refresher as you prepare to welcome your newest addition!<p>
        <p className='strong'>All bootcamps, including Veteran and Grandparent, are included for annual Nurture Members. Want to have support for you and your family for a whole year? Join Nurture!<p>
      </div>
    `,
    image: s_25_desktop,
    mobileImage: s_25_mobile,
    service: "service_25",
    url: url.VETERAN_BOOTCAMP_URL,
    privateClass: { text: "Book now" },
    giftCardOptions: [{ service: "25", price: "199", btn: "Book now" }],
  },
];

export const getServicePosthogEvent = (service: string) => {
  return serviceData.find((s: { service: string }) => s.service === service);
};

export const capturePurchasedServiceEvent = (s: string) => {
  const service = s && getServicePosthogEvent(s);

  if (service) {
    console.log(`[service-purchased]:${service.posthogEvent}`);
    posthog.capture(`[service-purchased]:${service.posthogEvent}`, {
      $set: {
        "last-service-purchased": service.posthogEvent,
      },
    });
  }
};

export const useCaptureServiceEvent = (step: number, steps: string[]) => {
  const posthog = usePostHog();
  const [searchParams] = useSearchParams();
  const serviceSearchParam = searchParams.get("service");

  useEffect(() => {
    const service =
      serviceSearchParam && getServicePosthogEvent(serviceSearchParam);

    if (service) {
      console.log(`[service-${steps[step]}]:${service.posthogEvent}`);
      posthog.capture(`[service-${steps[step]}]:${service.posthogEvent}`, {
        $set: {
          [`last-service-${steps[step]}`]: service.posthogEvent,
        },
      });
    }
  }, [serviceSearchParam, posthog, steps, step]);
};

export default serviceData;
