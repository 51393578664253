import React from "react";
import { Container, Row } from "react-bootstrap";
import "./SupportGroup.scss";
import New_Mom_Support_Groups from "../../../Assets/img/service/New_Mom_Support_Groups.jpg";
import Veterans_Moms_Survival_Group from "../../../Assets/img/service/Veterans_Moms_Survival_Group.jpg";
import Working_Moms_Survival_Group from "../../../Assets/img/service/Working_Moms_Survival_Group.jpg";
import Free_Breastfeeding_Support_Group from "../../../Assets/img/service/Free_Breastfeeding_Support_Group.jpg";
import supportGroupDad from "../../../Assets/img/service/supportGroupDad.jpg";
import pregnantGroup from "../../../Assets/img/service/pregnantGroup.jpg";
import { useNavigate } from "react-router-dom";
import LockScreen from "../../../Common/LockScreen/LockScreen";
import StreetCred from "../../../Common/streetCred/streetCred";
import { getDataFromLocalStorage } from "../../../Service/getLocalStorage";
import {
  BREASTFEEDING_SUPPORT_URL,
  DAD_S_GROUP_URL,
  NEW_MOM_S_GROUP_URL,
  PREGNANT_MOM_S_GROUP_URL,
  V_MOM_S_GROUP_URL,
  W_MOM_S_GROUP_URL,
} from "../../../Utils/ServiceUrl";
import LandingDescription from "../../../Common/LandingDescription";
import { HeroBannerHorizontal } from "../../../Common/HeroBanner";
import hero from "../../../Assets/img/service/support.png";

const SupportGroup = () => {
  const Navigate = useNavigate();
  const userData = getDataFromLocalStorage();
  let testimonialData = [
    {
      name: " Julie L",
      data: "NAPS is an incredible source of support for Moms (and Dads!) and I have been consistently impressed with the quality of all staff members. I have benefited from various programming they offer, including lactation consults, the Moms Survival Group as well as the free support group. Every time I join, I enjoy the fun, lighthearted environment paired with high quality information and camaraderie. So thankful for this organization! :)",
    },
    {
      name: "Lauren G.",
      data: "I recommend NAPS to any expectant mom!  What a wonderful experience to surround myself and my new baby with first time moms and seasoned supportive professionals.  The Moms Survival Group (MSG) offered education, advice, and a place to feel a lot less isolated!  My MSG has led to many friendships that will undoubtedly continue well into the future.",
    },
    {
      name: " Samantha B.",
      data: "NAPS  is providing an invaluable resource and community to moms & babies. The first few months of coming home with a newborn were made easier by the classes I was able to take through NAPS. Signing up for the New Mom Survival Group gave me a safe place to go each week with my newborn. Jamie was a wealth of knowledge - both professional but also honest and relatable. And it connected me with other new moms who have become great friends and extension of our support network.",
    },
  ];
  return (
    <div className="support-Group">
      <Container fluid>
        <div className="py-3">
          <HeroBannerHorizontal
            hero={hero}
            width={90}
            title={
              <>
                <div>Parenting Support</div>
                <div className="strong" style={{ color: "#F5CF70" }}>
                  that’s actually supportive
                </div>
              </>
            }
          />
        </div>

        <LandingDescription
          ySpace
          content={[
            <>
              From our signature Mom Survival Groups (MSGs) that cater to new,
              veteran, and working moms to our free breastfeeding (but really
              all kinds of feeding!) group,{" "}
              <span className="strong">we’ve got you covered!</span>
            </>,
            <>
              <span className="strong">Come for the content, </span> from our
              expert lead groups, and{" "}
              <span className="strong">leave with a community </span>
              of moms walking this motherhood journey alongside you.
            </>,
          ]}
        />

        <Row className="pale-bg offering-main">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <Container>
              <Row className="justify-content-center my-md-5 my-3 stage-offering-header">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="section-header text-center">
                    <h2 className="home-section-title py-0">
                      Our<span className="strong pink"> Offerings</span>
                    </h2>
                  </div>
                </div>
                <div className="col-lg-8 col-md-8 mt-2">
                  <h3 className="text-center stage-description">
                    Get the most out of our team of{" "}
                    <span className="strong">experienced nurses </span> with
                    these services.
                  </h3>
                </div>
              </Row>

              <Row className="justify-content-center align-items-center my-5">
                <div className="col-lg-5 col-md-5 col-sm-12 order-1 order-md-0 mt-4 mt-md-0">
                  <div className="offering-block-content me-0 me-md-5">
                    <h3 className="lh-1 w-100 ">
                      <span className="offer-number">1. </span>
                      Pregnant Moms Survival Group*
                    </h3>
                    <p>
                      <span className="strong">
                        Connect with expecting moms due the same month as you in
                        a 6-session series covering all things pregnancy.
                      </span>{" "}
                      Join us for support and expert guidance from a NAPS
                      registered nurse.{" "}
                    </p>
                    <button
                      className="w-auto btn-small teal-btn-small"
                      onClick={() => Navigate(PREGNANT_MOM_S_GROUP_URL)}
                    >
                      Learn More
                    </button>
                  </div>
                </div>
                <div className="col-lg-5 col-md-5 col-sm-12 order-0 order-md-1">
                  <div className="service-feature-img">
                    <div style={{ maxWidth: 415 }}>
                      <img
                        src={pregnantGroup}
                        className="img-fluid"
                        alt="Dads Survival Group"
                      />
                    </div>
                  </div>
                </div>
              </Row>

              <Row className="justify-content-center align-items-center my-5">
                <div className="col-lg-5 col-md-5 col-sm-12 order-1 order-md-1 mt-4 mt-md-0">
                  <div className="offering-block-content me-0 me-md-5">
                    <h3 className="lh-1 w-100 ">
                      <span className="offer-number">2. </span>
                      New Moms Survival Group*
                    </h3>
                    <p>
                      <span className="strong">
                        Just starting your parenting journey? Meet other moms
                        just like you.
                      </span>{" "}
                      Join a six-week, expert nurse run, virtual support group
                      for moms with babies the same age as yours.
                    </p>
                    <button
                      className="w-auto btn-small teal-btn-small"
                      onClick={() => Navigate(NEW_MOM_S_GROUP_URL)}
                    >
                      Learn More
                    </button>
                  </div>
                </div>
                <div className="col-lg-5 col-md-5 col-sm-12 order-0 order-md-0">
                  <div className="service-feature-img">
                    <img src={New_Mom_Support_Groups} className="img-fluid" />
                  </div>
                </div>
              </Row>

              <Row className="justify-content-center align-items-center my-5">
                <div className="col-lg-5 col-md-5 col-sm-12 order-md-1">
                  <div className="service-feature-img me-0 me-md-5">
                    <img
                      src={Veterans_Moms_Survival_Group}
                      className="img-fluid"
                    />
                  </div>
                </div>

                <div className="col-lg-5 col-md-5 col-sm-12 mt-4 mt-md-0">
                  <div className="offering-block-content ">
                    <h3 className="lh-1 w-100">
                      <span className="offer-number">3. </span>
                      Veteran Moms Survival Group*
                    </h3>
                    <p>
                      <span className="strong">
                        Been down this road before? Meet some other moms who are
                        parenting a newborn and an older kid or two.
                      </span>{" "}
                      Join a six-week, expert nurse run, virtual support group
                      for moms with newborns and older kids at home
                    </p>
                    <button
                      className="w-auto btn-small teal-btn-small"
                      onClick={() => Navigate(V_MOM_S_GROUP_URL)}
                    >
                      Learn More
                    </button>
                  </div>
                </div>
              </Row>

              <Row className="justify-content-center align-items-center my-5">
                <div className="col-lg-5 col-md-5 col-sm-12 order-0 ">
                  <div className="service-feature-img">
                    <img
                      src={Working_Moms_Survival_Group}
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="col-lg-5 col-md-5 col-sm-12 mt-4 mt-md-0">
                  <div className="offering-block-content me-0 me-md-5">
                    <h3 className="lh-1 w-100 ">
                      <span className="offer-number">4. </span>
                      Working Moms Survival Group*
                    </h3>
                    <p>
                      <span className="strong">
                        It will come as a surprise to no one to hear that
                        balancing parenting and work is seriously tough. Join a
                        group of people who really understand.
                      </span>{" "}
                      This 6-session virtual support group, led by experts on
                      our NAPS team, is designed for working moms of infants
                      and/or toddlers.
                    </p>
                    <button
                      className="w-auto btn-small teal-btn-small"
                      onClick={() => Navigate(W_MOM_S_GROUP_URL)}
                    >
                      Learn More
                    </button>
                  </div>
                </div>
              </Row>

              <Row className="justify-content-center align-items-center my-5">
                <div className="col-lg-5 col-md-5 col-sm-12 order-md-1">
                  <div className="service-feature-img me-0 me-md-5">
                    <img
                      src={Free_Breastfeeding_Support_Group}
                      className="img-fluid"
                    />
                  </div>
                </div>

                <div className="col-lg-5 col-md-5 col-sm-12 mt-4 mt-md-0">
                  <div className="offering-block-content ">
                    <h3 className="lh-1 w-100">
                      <span className="offer-number">5. </span>
                      Free Breastfeeding Support Group
                    </h3>
                    <p>
                      <span className="strong">
                        Feeding your baby (in anyway!) can be a truly special
                        journey… but that doesn’t mean it’s not tough. Meet some
                        other moms who are going through the same thing.
                      </span>{" "}
                      Join this free drop-in support group, held virtually or in
                      person every week.
                    </p>
                    <button
                      className="w-auto btn-small teal-btn-small"
                      onClick={() => Navigate(BREASTFEEDING_SUPPORT_URL)}
                    >
                      Learn More
                    </button>
                  </div>
                </div>
              </Row>

              <Row className="justify-content-center align-items-center my-5">
                <div className="col-lg-5 col-md-5 col-sm-12">
                  <div className="service-feature-img ">
                    <div style={{ maxWidth: 415 }}>
                      <img src={supportGroupDad} className="img-fluid" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-5 col-md-5 col-sm-12 mt-4 mt-md-0">
                  <div className="offering-block-content me-0 me-md-5">
                    <h3 className="lh-1 w-100">
                      <span className="offer-number">6. </span>
                      Dads Survival Group
                    </h3>
                    <p>
                      <span className="strong">
                        Join our Dads’ Survival Group and get live advice and
                        support on parenting from other dads.
                      </span>{" "}
                      Lead by a NAPS dad, these monthly meetups are designed to
                      create a supportive space for partners.
                    </p>
                    <button
                      className="w-auto btn-small teal-btn-small"
                      onClick={() => Navigate(DAD_S_GROUP_URL)}
                    >
                      Learn More
                    </button>
                  </div>
                </div>
              </Row>
            </Container>
          </div>

          {(!userData || !userData?.is_stripe_membership) && (
            <div className="col-lg-12 col-md-12 col-sm-12">
              <Container>
                <h3 className="px-2 mx-0 my-0 mt-5 text-left strong">
                  *MSG included for annual Nurture Members.
                </h3>
                <LockScreen />
              </Container>
            </div>
          )}
        </Row>
        <StreetCred data={testimonialData} />
      </Container>
    </div>
  );
};

export default SupportGroup;
